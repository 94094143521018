<template>
  <b-modal
    v-model="value"
    centered
    ok-only
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close
    ok-variant="warning"
    :ok-title="$t('admin.labs.opticSignWarn.sectionButton')"
    modal-class="modal-warning"
    :title="$t('admin.labs.opticSignWarn.sectionTitle')"
    @ok="$router.push(redirectTo)"
  >
    <b-card-text
      v-html="$t('admin.labs.opticSignWarn.sectionDescription')"
    ></b-card-text>
  </b-modal>
</template>

<script>
import { BCardText, BModal } from 'bootstrap-vue'

export default {
  name: 'OpticSignWarn',

  components: {
    BCardText,
    BModal,
  },

  props: {
    redirectTo: {
      type: String,
      required: true,
    },

    value: Boolean,
  },
}
</script>
